<template>
	<Modal
		:title="title"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			ref="processForm"
			:model="formData"
			label-position="top"
			v-if="processList.length"
		>
			<FormItem :label="`${getText('START')}流程`">
				<Tree
					:data="startModels"
					show-checkbox
					:check-directly="true"
					ref="startTree"
				></Tree>
			</FormItem>
			<FormItem :label="`${getText('ING')}流程`">
				<Tree
					:data="ingModels"
					show-checkbox
					:check-directly="true"
					ref="ingTree"
				></Tree>
			</FormItem>
			<FormItem :label="`${getText('OTHER')}流程`">
				<Tree
					:data="otherModels"
					show-checkbox
					:check-directly="true"
					ref="otherTree"
				></Tree>
			</FormItem>
			<FormItem :label="`${getText('END')}流程`">
				<Tree
					:data="endModels"
					show-checkbox
					:check-directly="true"
					ref="endTree"
				></Tree>
			</FormItem>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk">确定</Button>
		</div>
	</Modal>
</template>
<script>
import api from "@/api/project/processSetting"
import { mapState } from "vuex"

const { getProjFlowble, getAllFlowble, saveProjFlowble } = api
export default {
	props: ["visible", "updateId", "projectConfigId"],
	data() {
		return {
			loading: false,
			title: "流程设置",
			allProcess: [],
			models: [],
			formData: {}
		}
	},
	watch: {
		visible() {
			if (this.visible && this.updateId) {
				this.getModels()
			}
		}
	},
	computed: {
		...mapState("user", ["processList"]),
		startModels() {
			return this.allProcess.filter(el => el.category === "START")
		},
		ingModels() {
			return this.allProcess.filter(el => el.category === "ING")
		},
		otherModels() {
			return this.allProcess.filter(el => el.category === "OTHER")
		},
		endModels() {
			return this.allProcess.filter(el => el.category === "END")
		}
	},
	methods: {
		// 获取所有流程列表
		getModels() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getAllFlowble({
					isSubProcess: 0,
					projectId: this.updateId,
					projectConfigId: this.projectConfigId
				})
				if (res) {
					this.models = res.data.map(el => ({
						...el,
						title: el.name,
						checked: false
					}))
					this.$nextTick(() => {
						this.getCheckedModels()
					})
				}
				this.loading = false
			})
		},
		getCheckedModels() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getProjFlowble({ projectId: this.updateId })
				if (res) {
					this.allProcess = this.models.filter(el => {
						const model = res.data.find(o => el.id === o.modelId)
						let obj = {}
						if (model) {
							obj = Object.assign(el, {
								checked: true,
								disabled: model.status === 1 || el.isMustExecute === 1
							})
							return obj
						}
						if (el.isDisable === 0) {
							// 已启用 未勾选
							obj = Object.assign(el, {
								checked: false,
								disabled: false
							})
							return obj
						}
						return false
					})
				}
				this.loading = false
			})
		},
		getText(nowType) {
			const typeItem = this.processList.find(
				item => item.obj.nickname === nowType
			)
			return typeItem ? typeItem.name : ""
		},
		onCancel() {
			this.$emit("onCancle")
		},
		async onOk() {
			this.loading = true
			const start = this.$refs.startTree.getCheckedNodes().map(item => item.id)
			const ing = this.$refs.ingTree.getCheckedNodes().map(item => item.id)
			const other = this.$refs.otherTree.getCheckedNodes().map(item => item.id)
			const end = this.$refs.endTree.getCheckedNodes().map(item => item.id)
			const res = await saveProjFlowble({
				modelIds: [...start, ...ing, ...other, ...end],
				projectId: this.updateId
			})
			if (res) {
				this.$Message.success("流程设置成功！")
				this.$emit("onOk")
			}
			this.loading = false
		}
	}
}
</script>
