<template>
	<Modal
		class="add-org-modal"
		:value="visible"
		:fullscreen="fullscreen"
		:mask-closable="false"
		:styles="{ top: fullscreen ? 0 : '32px' }"
		:footer-hide="true"
		@on-cancel="onCancel"
		width="1150"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="add-org-modal-header">
			<h2>组织管理</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<div class="content-wrap">
			<Spin fix v-if="loading"></Spin>
			<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
				<CtmsSearchBoxItem :span="1" label="组织类型">
					<Select
						v-model="searchParams.type"
						:transfer="true"
						placeholder="请选择组织类型"
					>
						<Option :value="list.id" v-for="list in typeList" :key="list.id">{{
							list.name
						}}</Option>
					</Select>
				</CtmsSearchBoxItem>
				<CtmsSearchBoxItem :span="1" label="名称">
					<Input placeholder="请输入名称" v-model="searchParams.name" />
				</CtmsSearchBoxItem>
				<CtmsSearchBoxItem :span="1" label="状态">
					<Select
						v-model="searchParams.isDel"
						:transfer="true"
						placeholder="请选择状态"
						clearable
					>
						<Option :value="0">启用</Option>
						<Option :value="1">禁用</Option>
					</Select>
				</CtmsSearchBoxItem>
			</CtmsSearchBox>
			<CtmsAction>
				<Button
					@click="handleAddShow"
					v-if="
						!(systemActionPermissions.indexOf('btn_setting_org_add') === -1)
					"
					>新增</Button
				>
			</CtmsAction>
			<CtmsDataGrid
				:height="dataGridHeight"
				:columns="columns"
				:data="listData"
				:page="page"
			></CtmsDataGrid>
			<Add
				type="modal"
				:visible="addVisible"
				:typeList="addTypeList"
				@onCancel="addVisible = false"
				@onOk="handleAddOk"
				:updateId="updateId"
			></Add>
			<ViewDetail
				type="modal"
				:visible="viewVisible"
				@onCancel="viewVisible = false"
				:viewId="viewId"
			></ViewDetail>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/organization"
import publicApi from "@/api/public"
import { mapState } from "vuex"
import ViewDetail from "./ViewDetail.vue"
import Add from "./Add.vue"

const { apiGetPage, apiDelete } = api
const { publicGetDictionaryList } = publicApi

const columns = [
	{
		title: "全称",
		key: "name",
		minWidth: 200
	},
	{
		title: "简称",
		key: "nickname",
		minWidth: 200
	},
	{
		title: "类型",
		key: "typeName",
		minWidth: 100
	},
	{
		title: "联系电话",
		key: "phone",
		minWidth: 170
	},
	{
		title: "状态",
		key: "isDel",
		minWidth: 100,
		render: (h, params) => h("span", {}, params.row.isDel ? "禁用" : "启用")
	}
]

export default {
	name: "indexmodal",
	props: ["visible"],
	components: {
		Add,
		ViewDetail
	},
	data() {
		return {
			fullscreen: false,
			listData: [],
			columns,
			actionColumn: {
				title: "操作",
				key: "action",
				width: 180,
				fixed: "right",
				renderButton: params => {
					const actionList = [
						{
							label: "查看",
							on: {
								click: this.handleViewShow
							}
						},
						{
							label: "修改",
							on: {
								click: this.handleUpdateShow
							}
						},
						{
							label: params.row.isDel ? "启用" : "禁用",
							on: {
								click: this.handleSwitchState
							},
							confirm: {
								title: `您确认${params.row.isDel ? "启用" : "禁用"}这条数据吗？`
							}
						}
					]
					if (
						this.systemActionPermissions.indexOf("btn_setting_org_add") === -1
					) {
						actionList[1] = null
						actionList[2] = null
					}
					return actionList.filter(item => !!item)
				}
			},
			loading: false,
			addVisible: false,
			viewVisible: false,
			updateId: "",
			viewId: "",
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				type: "",
				queryLike: "",
				isDel: 0
			},
			oldSearchParams: {
				type: "",
				queryLike: "",
				isDel: 0
			},
			typeList: [],
			isAdd: false
		}
	},
	created() {
		this.getTypeList()
		this.initList()
		this.columns = [...columns, this.actionColumn]
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 150
		}),
		...mapState("permission", ["systemActionPermissions"]),
		addTypeList() {
			return this.typeList.filter(item => item.id !== "104")
		}
	},
	methods: {
		onCancel() {
			this.$emit("closeOrgModal", this.isAdd)
			this.fullscreen = false
			this.isAdd = false
		},
		getTypeList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await publicGetDictionaryList({ type: 1, isDel: 0 })
				if (res) {
					this.typeList = res.data[0].leaf
				}
				this.loading = false
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},

		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.updateId = ""
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.isAdd = true
			this.addVisible = false
			this.initList()
		},
		// 修改显示
		handleUpdateShow({ row }) {
			this.addVisible = true
			this.updateId = row.id
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				type: "",
				queryLike: "",
				isDel: 0
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 禁用
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				res = await apiDelete({ id: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 查看显示
		handleViewShow({ row }) {
			this.viewVisible = true
			this.viewId = row.id
		}
	}
}
</script>
<style lang="less">
.add-org-modal {
	position: relative;
	.title {
		font-size: 15px;
		font-weight: bold;
		padding: 16px 0;
		border-top: dashed 1px #515a6e;
	}
	.ivu-form-item-required .ivu-form-item-label:before {
		font-size: 12px;
	}
	.add-org-modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.add-org-btn {
			position: absolute;
			right: 100px;
			top: 11px;
			padding: 2px 15px;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
	.content-wrap {
		padding: 0 16px;
	}
}
</style>
