<template>
	<div class="content-wrap">
		<Spin fix v-if="loading || tableLoading"></Spin>
		<CtmsSearchBox
			v-if="isXinhua || projectStatus === 1"
			@onSearch="searchHandle"
			@onReset="reset"
		>
			<CtmsSearchBoxItem :span="1" label="项目名称">
				<Input
					placeholder="请输入项目名称"
					v-model="searchParams.projectName"
				/>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="项目类型">
				<Select
					v-model="searchParams.projectConfigId"
					placeholder="请选择项目类型"
					:transfer="true"
					clearable
				>
					<Option
						v-for="item in projTypeList"
						:value="item.id"
						:key="item.id"
						>{{ item.typeName }}</Option
					>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="承担科室">
				<Select
					v-model="searchParams.departments"
					placeholder="请选择承担科室"
					class="depart-select"
					:max-tag-count="1"
					:transfer="true"
					filterable
					multiple
				>
					<Option
						v-for="item in departmentList"
						:value="item.name"
						:key="item.id"
						>{{ item.name }}</Option
					>
				</Select>
				<!--<Input placeholder="请输入承担科室"-->
				<!--v-model="searchParams.department" />-->
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="主要研究者(PI)">
				<Select
					v-model="searchParams.trialSitePiNames"
					placeholder="请选择主要研究者(PI)"
					class="depart-select"
					:max-tag-count="1"
					:transfer="true"
					filterable
					multiple
				>
					<Option
						v-for="item in userList"
						:value="item.realName"
						:key="item.id"
						>{{ item.realName }}</Option
					>
				</Select>
				<!--<Input placeholder="请输入主要研究者(PI)"-->
				<!--v-model="searchParams.trialSitePiNames" />-->
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem v-if="!isXinhua" :span="1" label="创建人">
				<Input placeholder="请输入创建人" v-model="searchParams.createdBy" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem
				v-if="!isXinhua"
				:span="1"
				label="伦理批件有效日期查询"
			>
				<DatePicker
					type="daterange"
					placeholder="请选择伦理批件有效日期"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="queryTimes"
					@on-change="v => (queryTimes = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem v-if="isXinhua" :span="1" label="申请日期">
				<DatePicker
					type="daterange"
					placeholder="请选择申请日期"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="项目阶段">
				<Select
					v-model="searchParams.projectStatus"
					clearable
					:transfer="true"
					placeholder="请选择项目阶段"
				>
					<Option
						:value="item.obj.nickname"
						v-for="item in projectStepInfo"
						:key="item.obj.nickname"
						>{{ item.name }}</Option
					>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="项目编号">
				<Input placeholder="请输入项目编号" v-model="searchParams.projectNo" />
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<div class="choose-project-drafts">
				<RadioGroup
					v-model="projectStatus"
					@on-change="handleChangeList"
					type="button"
				>
					<Radio :label="1">项目列表 </Radio>
					<Radio :label="0">暂存信息 </Radio>
				</RadioGroup>
				<span class="line"></span>
			</div>
			<Button
				class="add-project-btn ivu-btn-right"
				icon="md-add"
				style="margin-right: 0"
				@click="handleShowProjType"
				v-if="!(systemActionPermissions.indexOf('btn_project_add') === -1)"
				>新增项目</Button
			>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="allColumns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<!--选择项目类型-->
		<Modal
			class="project-type-modal"
			v-model="addTypeVisible"
			title="选择新增项目类型"
			:styles="{ top: '10%' }"
			:mask-closable="false"
			@on-cancel="addTypeVisible = false"
		>
			<RadioGroup v-model="projType" vertical>
				<Radio :label="item.id" v-for="(item, idx) in projTypeList" :key="idx"
					>{{ item.typeName }}
				</Radio>
			</RadioGroup>
			<div slot="footer">
				<Button @click="addTypeVisible = false">取消</Button>
				<Button type="primary" @click="handleAddShow">确定</Button>
			</div>
		</Modal>
		<!--新增修改项目-->
		<Add
			:visible="addVisible"
			:status="projectStatus"
			:componentList.sync="componentList"
			:proj-type="projType"
			:proj-type-name="projTypeName"
			:updateId="updateId"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
		></Add>
		<ViewDetail
			:visible="viewVisible"
			@onCancel="viewVisible = false"
			:updateId="updateId"
			:roleLabel="roleLabel"
			:roleName="currentRoleName"
		></ViewDetail>
		<ProcessSetting
			:visible="processVisible"
			@onCancle="processVisible = false"
			@onOk="processSettingOk"
			:updateId="updateId"
			:projectConfigId="projectConfigId"
		>
		</ProcessSetting>
		<todo-list
			:visible="toDoVisible"
			type="projectProcess"
			:proId="updateId"
			@onCancel="toDoVisible = false"
		></todo-list>
	</div>
</template>

<script>
import api from "@/api/project/project"
import contractApi from "@/api/project/contract"
import publicApi from "@/api/public"
import TodoList from "@/views/Workbench/components/ToDoList.vue"
import { mapState } from "vuex"
import Add from "./Add.vue"
import ViewDetail from "./ViewDetail.vue"
import ProcessSetting from "./processSetting.vue"

const {
	apiGetPage,
	apiDelete,
	getDraftsList,
	delDrafts,
	getProjectDetail,
	getProjectRoleCol,
	apiGetFormSetting,
	apiGetProjTypeList
} = api
const { publicGetuserList } = publicApi
const { apiGetDeparts } = contractApi

export default {
	name: "index",
	components: {
		Add,
		ViewDetail,
		ProcessSetting,
		TodoList
	},
	data() {
		return {
			isShanghaiertong: false,
			toDoVisible: false,
			projType: "", // 新增项目类型Id
			projTypeName: "", // 新增项目类型名称
			projTypeList: [], // 项目类型列表
			projectStatus: 1, // 項目状态 1 已发布项目 0 草稿箱
			listData: [],
			times: [],
			queryTimes: [],
			roleLabel: "", // 机构秘书列动态列名
			currentRoleName: "", // 当前项目的机构秘书名称
			allColumns: [],
			columns: [
				{
					title: "项目阶段",
					key: "projectStep",
					minWidth: 100,
					render: (h, params) => {
						const statusStr = this.projectAllStepInfo.find(
							x => Number(x.obj.nickname) === params.row.projectStep
						)
						return h("span", {}, statusStr ? statusStr.name : "")
					}
				},
				{
					title: "项目类型",
					key: "projectConfigName",
					minWidth: 150
				},
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 300,
					render: (h, params) =>
						h(
							"span",
							{
								style: {
									color: "#2d8cf0",
									cursor: "pointer"
								},
								on: {
									click: e => {
										sessionStorage.setItem(
											"searchParams",
											JSON.stringify({
												params: {
													...this.oldSearchParams
												},
												page: {
													current: this.page.current,
													pageSize: this.page.pageSize
												}
											})
										)
										this.$router.push({
											path: `/project/${params.row.projectId}`
										})
									}
								}
							},
							params.row.projectName
						)
				},
				{
					title: "申办者",
					key: "trialSponsorName",
					minWidth: 130
				},
				{
					title: "承担科室",
					key: "department",
					minWidth: 100
				},
				{
					title: "主要研究者(PI)",
					key: "trialSiteUsersName",
					minWidth: 130
				},
				// 动态获取列名
				{
					title: "机构秘书",
					key: "projectManagers",
					minWidth: 140
				},
				{
					title: "方案编号",
					key: "programmeNumber",
					minWidth: 130
				},
				{
					title: "机构立项编号",
					key: "mechanismProjectNo",
					minWidth: 130
				},
				{
					title: "伦理批件号",
					key: "ethicApprovalNo",
					minWidth: 130
				},
				{
					title: "伦理批件签发日期",
					key: "ethicApprovalDate",
					minWidth: 130
				},
				{
					title: "伦理批件到期日期",
					key: "ethicDueDate",
					minWidth: 130
				},
				// {
				//   title: '主要研究者',
				//   key: 'researcher',
				//   minWidth: 120,
				// },
				{
					title: "创建人",
					key: "createUser",
					minWidth: 120
				},
				{
					title: "创建时间",
					key: "createTime",
					minWidth: 130
				}
			],
			actionColumn: [
				{
					title: "操作",
					key: "action",
					minWidth: 210,
					fixed: "right",
					renderButton: params => {
						const actionList = [
							{ // 0
							  label: '进入项目',
							  on: {
							    click: () => {
							      sessionStorage.setItem(
							        'searchParams',
							        JSON.stringify({
							          params: {
							            ...this.oldSearchParams,
							          },
							          page: {
							            current: this.page.current,
							            pageSize: this.page.pageSize,
							          },
							        }),
							      );
							      this.$router.push({
							        path: `/project/${params.row.projectId}`,
							      });
							    },
							  },
							},
							{ // 1
							  label: '运行流程',
							  on: {
							    click: this.handleViewProcess,
							  },
							},
							{ // 2
								label: "查看",
								on: {
									click: this.handleViewShow
								}
							},
							{ // 3
								label: this.projectStatus === 1 ? "修改" : "继续补录",
								on: {
									click: this.handleUpdateShow
								}
							},
							{ // 4
								label: "流程设置",
								on: {
									click: this.processSetting
								}
							},
							{ // 5
								label: "删除",
								on: {
									click: this.handleDelete
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							},
							{ // 6
								label: "受试者管理",
								on: {
									click: this.handleJump
								}
							}
						]
            actionList[0] = null
            actionList[1] = null
						if (!this.isShetyy && !this.isXinhua) {
							actionList[6] = null
						}

						if (this.isShetyy) {
							if (
								!params.row.otrialProjectId ||
								!(params.row.toOtrialRoles && params.row.toOtrialRoles.length)
							) {
								actionList[6] = null
							}
						}

						if (this.isXinhua) {
							if (
								!params.row.otrialProjectId ||
								!(params.row.toOtrialRoles && params.row.toOtrialRoles.length)
							) {
								actionList[6] = null
							}
						}

						if (this.projectStatus !== 1) {
							// 继续补录
							actionList[0] = null
							actionList[1] = null
							actionList[2] = null
							actionList[4] = null
							actionList[5] = null
							if (
								this.systemActionPermissions.indexOf("btn_project_add") === -1
							) {
								actionList[3] = null
							} else {
								actionList.push({
									label: "删除草稿",
									on: {
										click: this.handleDeleteDrafts
									},
									confirm: {
										title: "您确认删除这条草稿数据吗？"
									}
								})
							}
						} else {
							// 修改
							if (
								this.systemActionPermissions.indexOf("btn_project_update") ===
								-1
							) {
								actionList[3] = null
							}
							if (
								this.systemActionPermissions.indexOf("btn_project_delete") ===
								-1
							) {
								actionList[5] = null
							}
							if (
								this.systemActionPermissions.indexOf(
									"btn_project_model_add"
								) === -1
							) {
								actionList[4] = null
							}
							if (
								this.systemActionPermissions.indexOf(
									"btn_project_running_process"
								) === -1
							) {
								// 运行流程
								actionList[1] = null
							}
						}

						return actionList.filter(item => !!item)
					}
				}
			],
			loading: false,
			tableLoading: false,
			addTypeVisible: false,
			addVisible: false,
			viewVisible: false,
			processVisible: false,
			updateId: "",
			projectConfigId: "", // 项目类型配置id
			viewId: "",
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				projectName: "",
				createdBy: "",
				projectNo: "",
				trialSitePiNames: [],
				projectConfigId: "",
				departments: [],
				projectStatus: "",
				queryStartDate: this.queryTimes ? this.queryTimes[0] : "",
				queryEndDate: this.queryTimes ? this.queryTimes[1] : "",
				timeFrom: this.times ? this.times[0] : "",
				timeTo: this.times ? this.times[1] : ""
			},
			oldSearchParams: {},
			departmentList: [],
			userList: [],
			orgId: this.$store.state.user.info.orgId
				? this.$store.state.user.info.orgId
				: "",
			componentList: [],
			headers: []
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130,
			projectStepInfo: state => state.user.projectStepInfo,
			projectAllStepInfo: state => state.user.projectAllStepInfo
		}),
		...mapState("permission", ["systemActionPermissions"]),
		env() {
			return this.$store.state.environment
		},
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		},
		isShetyy() {
			return this.$store.state.environment === "shetyy"
		},
		isZhongshan() {
			return this.$store.state.environment === "zhongshan"
		},
		...mapState("user", ["info"])
	},
	created() {
		this.getPublicData()
		this.initList("init")
		this.getProjType()
		localStorage.setItem("orgId", this.orgId)
	},
	methods: {
		async getProjType() {
			// 获取 新增项目类型 列表
			const res = await apiGetProjTypeList({ isDesigned: 1 }) // 只显示已配置过表单的项目类型。
			if (res) {
				this.projTypeList = res.data || []
			}
		},
		initList(type) {
			if (type === "init") {
				const paramsStr = sessionStorage.getItem("searchParams")
				if (paramsStr) {
					const { params, page } = JSON.parse(paramsStr)
					if (params.timeFrom) {
						this.times = [params.timeFrom, params.timeTo]
					}
					if (params.queryStartDate) {
						this.queryTimes = [params.queryStartDate, params.queryEndDate]
					}
					this.oldSearchParams = { ...params }
					this.searchParams = {
						...this.searchParams,
						...params
					}
					this.page.current = page.current
					this.page.pageSize = page.pageSize
				}
				sessionStorage.setItem("searchParams", "")
			}
			this.allColumns = []
			let currentColumns = []
			this.$asyncDo(async () => {
				this.tableLoading = true
				const piNames =
					this.oldSearchParams.trialSitePiNames &&
					this.oldSearchParams.trialSitePiNames.length
						? this.oldSearchParams.trialSitePiNames.join(",")
						: ""
				const departNames =
					this.oldSearchParams.departments &&
					this.oldSearchParams.departments.length
						? this.oldSearchParams.departments.join(",")
						: ""
				const res = await apiGetPage({
					...this.oldSearchParams,
					trialSitePiNames: piNames,
					departments: departNames,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					if (this.isZhongshan) {
						// 中山环境 获取表头
						this.headers = []
						const obj = res.data.list[0]
						for (const key in obj) {
							this.headers.push({
								title: obj[key],
								key
							})
						}
						const arr = this.headers.filter(
							item => item.key !== "projectId" && item.key !== "projectConfigId"
						)
						currentColumns = arr.map(item =>
							Object.assign({ minWidth: 150 }, item)
						)
						this.listData =
							res.data.list.length > 1 ? res.data.list.slice(1) : []
					} else {
						this.listData = res.data.list || []
						if (this.isXinhua) {
							currentColumns = this.getXinhua()
						} else if (this.isShetyy) {
							const indexClunm = this.columns.filter(item => {
								if (
									item.key === "trialSponsorName" ||
									item.key === "ethicApprovalNo" ||
									item.key === "ethicApprovalDate" ||
									item.key === "ethicDueDate"
								) {
									return false
								}
								return true
							})
							indexClunm.forEach(element => {
								if (element.key === "mechanismProjectNo") {
									element.title = "中心立项编号"
								}
								if (element.key === "projectManagers") {
									element.title = "研究助理"
								}
							})
							currentColumns = [...indexClunm]
						} else {
							this.getRoleCols()
							currentColumns = [...this.columns]
						}
					}
					this.allColumns = [...currentColumns, ...this.actionColumn]
					if (this.isXinhua) {
						this.allColumns = this.allColumns.filter(
							item => item.key !== "createUser"
						)
					}
					this.page.total = res.data.total
				}
				this.tableLoading = false
			})
		},
		getRoleCols() {
			// 动态获取列名
			this.$asyncDo(async () => {
				const res = await getProjectRoleCol(2)
				if (res && res.data) {
					this.columns[6].title = res.data.name
					this.roleLabel = res.data.name
				}
			})
		},
		getXinhua() {
			// 环境
			const newColumns = [...this.columns]
			newColumns.splice(
				4,
				8,
				{
					title: "申请日期",
					key: "applyDate",
					minWidth: 100,
					render: (h, params) =>
						h(
							"span",
							{},
							params.row.applyDate ? params.row.applyDate.split(" ")[0] : ""
						)
				},
				{
					title: "承担科室",
					key: "department",
					minWidth: 100
				},
				{
					title: "主要研究者",
					key: "researcher",
					minWidth: 120
				}
			)
			return newColumns
		},
		getPublicData() {
			this.$asyncDo(async () => {
				const res = await publicGetuserList({
					isDistinc: 1 // 去除重名信息
				})
				if (res) {
					this.userList = res.data || []
				}
				const res2 = await apiGetDeparts()
				if (res2) {
					this.departmentList = res2.data || []
				}
			})
		},
		// 切换 发布项目 和 草稿箱列表
		handleChangeList() {
			this.reset()
		},
		// 草稿箱
		async initDrafts() {
			this.loading = true
			const piNames =
				this.oldSearchParams.trialSitePiNames &&
				this.oldSearchParams.trialSitePiNames.length
					? this.oldSearchParams.trialSitePiNames.join(",")
					: ""
			const departNames =
				this.oldSearchParams.departments &&
				this.oldSearchParams.departments.length
					? this.oldSearchParams.departments.join(",")
					: ""

			const res = await getDraftsList({
				...this.oldSearchParams,
				trialSitePiNames: piNames,
				departments: departNames,
				pageNum: this.page.current,
				pageSize: this.page.pageSize
			})
			if (res) {
				this.listData = res.data.list
				this.page.total = res.data.total
			}
			this.loading = false
		},
		handleShowProjType() {
			this.projType = ""
			this.addTypeVisible = true
		},
		// 新增项目显示
		handleAddShow() {
			if (!this.projType) {
				this.$Message.warning("请选择新增项目的类型")
				return
			}
			this.projTypeName = this.projTypeList.find(
				item => item.id === this.projType
			).typeName
			this.apiGetFormSetting(this.projType)
		},
		async apiGetFormSetting(id) {
			const res = await apiGetFormSetting(id)
			if (res) {
				const { json } = res.data
				if (json) {
					this.componentList = JSON.parse(json)
					this.addVisible = true
					this.addTypeVisible = false
					this.updateId = ""
				}
			}
		},
		// 新增成功刷新列表
		handleAddOk(type) {
			this.addVisible = false
			this.projectStatus = Number(type)
			this.$nextTick(() => {
				this.page.current = 1
				if (type === 1) {
					this.initList()
					// 更新项目列表
					this.$store.dispatch("project/getProjectList")
				} else {
					this.initDrafts()
				}
			})
		},
		handleViewShow({ row }) {
			this.componentList = []
			this.viewVisible = true
			this.currentRoleName = row.projectManagers
			this.updateId = row.projectId
		},
		// 删除草稿
		async handleDeleteDrafts({ row }) {
			this.loading = true
			let res = false
			res = await delDrafts(row.projectId)
			if (res) {
				this.$Message.success("删除草稿成功！")
				this.initDrafts()
			} else {
				this.loading = false
			}
		},
		// 修改显示
		handleUpdateShow({ row }) {
			this.componentList = row.jsonData ? JSON.parse(row.jsonData) : []
			this.addVisible = true
			this.updateId = row.projectId
		},
		// 查看正在运行的流程节点
		handleViewProcess({ row }) {
			this.toDoVisible = true
			this.updateId = row.projectId
		},
		// 流程设置
		processSetting({ row }) {
			this.processVisible = true
			this.updateId = row.projectId
			this.projectConfigId = row.projectConfigId
		},
		// 流程设置成功
		processSettingOk() {
			this.processVisible = false
			this.updateId = ""
			this.projectConfigId = ""
		},
		// 受试者管理 跳转到O-trial
		async handleJump({ row }) {
			const piNames =
				this.oldSearchParams.trialSitePiNames &&
				this.oldSearchParams.trialSitePiNames.length
					? this.oldSearchParams.trialSitePiNames.join(",")
					: ""
			const departNames =
				this.oldSearchParams.departments &&
				this.oldSearchParams.departments.length
					? this.oldSearchParams.departments.join(",")
					: ""
			window.history.pushState(
				{
					...this.oldSearchParams,
					trialSitePiNames: piNames,
					departments: departNames,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				},
				null,
				window.location.href
			)
			const email = this.info.username || ""
			const mobile = this.info.phone || ""
			const realName = this.info.realName || ""
			const roles = row.toOtrialRoles.join(",")
			// xinhua 网址拼接  中心 id：60829ac7685b3fbb91d34db2
			// shetyy 网址拼接  儿童医院中心 id：5badcb67219ee349a4c23cfa

			// 测试环境 http://121.37.153.7:9883/web/login
			// 正式环境 http://o-trial.xinhuamed.com.cn:9180/

			// 儿童医院测试环境 http://20.50.54.52:8192/web/login
			// 儿童医院正式环境 https://plus.o-trial.com/
			let id = ""
			let url = ""
			if (this.isXinhua) {
				id = row.otrialProjectId
				// 生产环境
				url = `http://o-trial.xinhuamed.com.cn:9180/web/project/${id}/site/60829ac7685b3fbb91d34db2/sitesubjects?env=xinhua&email=${email}&mobile=${mobile}&id=${id}&realName=${realName}&roles=${roles}`
				// 测试环境
				// url = `http://120.92.138.226:8092/web/project/${id}/site/60829ac7685b3fbb91d34db2/sitesubjects?env=xinhua&email=${email}&mobile=${mobile}&id=${id}&realName=${realName}&roles=${roles}`;
				// 本地环境
				// url = `http://120.92.138.226:8092/web/project/${id}/site/60829ac7685b3fbb91d34db2/sitesubjects?env=xinhua&email=${email}&mobile=${mobile}&id=${id}&realName=${realName}&roles=${roles}`;
			} else if (this.isShetyy) {
				if (!row.otrialProjectId) {
					this.loading = true
					let res = ""
					res = await getProjectDetail(row.projectId)
					if (res) {
						id = res.data.project.otrialProjectId
					}
					this.loading = false
				} else {
					id = row.otrialProjectId
				}
				// 原来的儿童医院siteId  5badcb67219ee349a4c23cfa
				// 新的儿童医院projSiteId  6178ee50232a2a145a4683ba
				// http://plus.test.o-trial.com/web/home 新版测试环境

				// url = `http://20.50.54.55:8192/web/project/${id}/site/6178ee50232a2a145a4683ba/sitesubjects?env=shetyy&email=${email}&mobile=${mobile}&id=${id}&realName=${realName}&roles=${roles}`;
				url = `https://plus.o-trial.com/web/project/${id}/site/6178ee50232a2a145a4683ba/sitesubjects?env=shetyy&email=${email}&mobile=${mobile}&id=${id}&realName=${realName}&roles=${roles}`
			}
			window.location.replace(url)
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				if (this.projectStatus === 1) {
					this.initList()
				} else {
					this.initDrafts()
				}
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.current = 1
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				if (this.projectStatus === 1) {
					this.initList()
				} else {
					this.initDrafts()
				}
			})
		},
		// 重置
		reset() {
			this.times = []
			this.queryTimes = []
			this.searchParams = {
				projectName: "",
				createdBy: "",
				projectNo: "",
				trialSitePiNames: [],
				projectConfigId: "",
				departments: [],
				projectStatus: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			Object.assign(this.searchParams, {
				timeFrom: this.times ? this.times[0] : "",
				timeTo: this.times ? this.times[1] : "",
				queryStartDate: this.queryTimes ? this.queryTimes[0] : "",
				queryEndDate: this.queryTimes ? this.queryTimes[1] : ""
			})
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				if (this.projectStatus === 1) {
					this.initList()
				} else {
					this.initDrafts()
				}
			})
		},
		// 删除
		handleDelete({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiDelete({ id: row.projectId })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
					this.loading = false
				} else {
					this.loading = false
				}
			})
		}
	}
}
</script>
<style lang="less">
.depart-select.ivu-select-multiple {
	min-height: 32px;
}
.depart-select.ivu-select-multiple .ivu-tag {
	max-width: 90px;
}
.choose-project-drafts {
	position: relative;
	display: inline-block;
	.line {
		position: absolute;
		left: 50%;
		top: 8px;
		z-index: 2;
		width: 2px;
		height: 50%;
		background: #dcdee2;
	}
	.ivu-radio-group-button {
		.ivu-radio-wrapper {
			border: none;
			border-bottom: 1px solid #dcdee2;
			box-shadow: none;
			font-size: 15px;
			&:after {
				width: 0;
				background: #fff;
			}
		}
		.ivu-radio-wrapper-checked {
			color: #2d8cf0;
			border-bottom: 1px solid #2d8cf0;
			box-shadow: none;
			&.ivu-radio-focus {
				box-shadow: none;
				&:after {
					background: #fff;
					width: 0;
				}
			}
		}
		.ivu-radio-wrapper:last-child,
		.ivu-radio-wrapper.ivu-radio-wrapper-checked:last-child {
			&:before {
				width: 0;
				/*background: #dcdee2;*/
			}
		}
	}
}

.add-project-btn {
	margin-left: 30px;
	padding: 3px 20px 4px;
	border-color: #2d8cf0;
	color: #2d8cf0;
	font-size: 14px;
}
.project-type-modal {
	max-height: 550px;
	.ivu-radio-group {
		display: block;
	}
	.ivu-radio-group-vertical .ivu-radio-wrapper {
		margin: 15px;
		padding: 7px 15px;
		min-height: 34px;
		height: unset;
		line-height: 20px;
		white-space: normal;
		word-break: break-all;
		border: 1px solid #bfbfbf;
		border-radius: 5px;
		&:hover {
			border-color: #2d8cf0;
		}
	}
	.ivu-radio-inner {
		margin-right: 6px;
		border-color: #bfbfbf;
	}
}
</style>
