<template>
	<Modal
		class="view-pro-modal"
		:value="visible"
		:fullscreen="fullscreen"
		:mask-closable="false"
		:styles="{ top: fullscreen ? 0 : '20px' }"
		@on-cancel="onCancel"
		width="1150"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="view-pro-modal-header">
			<h2>查看项目——{{ projectTypeName }}</h2>
			<a href="javascript:;" class="fullscreen" @click="handleFullScreen">
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
			<span
				v-if="projectInfo.terminateDate"
				style="
					color: red;
					float: right;
					line-height: 20px;
					margin-right: 20px;
					font-weight: 700;
				"
				>项目已终止</span
			>
		</div>
		<Tabs v-model="tabName">
			<TabPane label="项目信息" name="info">
				<div
					style="margin-left: 26px; color: red; font-size: 14px"
					v-if="isXinhua && isOpenHgc !== ''"
				>
					是否创建遗传办文档：{{
						isOpenHgc === 1 ? "是" : isOpenHgc === 0 ? "否" : ""
					}}
				</div>
				<div class="genetic-con" :style="`height:${conHeight}px`">
					<p class="role-name-class" v-if="!isShowRole2 && roleLabel">
						{{ roleLabel }}：{{ roleName || "--" }}
					</p>
					<designTemplate
						:componentList="componentList"
						ref="designTemplate_add_project"
						:updateId="updateId"
						:projectInfo="projectInfo"
						:is-no-height="true"
						entry="project"
						:fullscreen="fullscreen"
						show-type="history"
					></designTemplate>
					<div class="genetic-wrapper" v-if="!isXinhua">
						<h2>遗传办信息</h2>
						<Checkbox
							v-model="isOpenHgc"
							disabled
							:true-value="1"
							:false-value="0"
						>
							是否创建遗传办文档
						</Checkbox>
					</div>
					<!--<designTemplate-->
					<!--v-if="!isXinhua"-->
					<!--:componentList="hgcComponentList"-->
					<!--ref="designTemplate_view_genetic"-->
					<!--:is-no-height="true"-->
					<!--:updateId="updateId"-->
					<!--entry="project"-->
					<!--show-type="history"></designTemplate>-->
				</div>
			</TabPane>
			<TabPane
				label="立项资料"
				name="files"
				v-if="
					systemActionPermissions.indexOf('btn_project_information_view') > -1
				"
				class="tab-files-wrap"
			>
				<!--流程文档库-->
				<Document
					v-if="visible"
					:fileOpFunc="fileOpFunc"
					:defaultDoc="defaultDoc"
					:previewUrl="previewUrl"
					:downloadUrl="downloadUrl"
					:projectId="updateId"
				></Document>
			</TabPane>
		</Tabs>

		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/project"
import processApi from "@/api/project/processDoc"
import designTemplate from "@/components/BusinessComponents/FormDesign/designTemplate.vue"
import ExtraMixin from "@/mixins/ExtraMixin"
import Document from "@/components/BusinessComponents/Document/Index.vue"
import { mapState } from "vuex"

const { apiGet } = api
const { apiGetPage } = processApi

export default {
	name: "viewPro",
	props: ["visible", "updateId", "roleLabel", "roleName"],
	mixins: [ExtraMixin],
	components: {
		designTemplate,
		Document
	},
	data() {
		return {
			projectTypeName: "",
			componentList: [],
			hgcComponentList: [],
			loading: false,
			fullscreen: false, // 是否全屏弹出框
			formData: {},
			title: "",
			isOpenHgc: 0,
			tabName: "info",
			fileOpFunc: {
				apiGetPage
			},
			downloadUrl: "",
			previewUrl: "",
			defaultDoc: {
				id: "2",
				name: "流程文档库",
				type: "view" // type: view 表示只能进行查看，不能进行其他操作
			},
			projectInfo: {}
		}
	},
	computed: {
		...mapState({
			contentHeight: state => state.contentHeight - 90
		}),
		conHeight() {
			return this.fullscreen
				? document.body.clientHeight - 200
				: this.contentHeight
		},
		...mapState("permission", ["systemActionPermissions"]),
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		},
		isShowRole2() {
			return (
				this.$store.state.environment === "zhongshan" ||
				this.$store.state.environment === "xinhua"
			)
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.tabName = "info"
				this.downloadUrl = `${this.$baseUrl}/project/${this.updateId}/docfile`
				this.previewUrl = `${this.$baseUrl}/project/${this.updateId}/docfile`
				this.$asyncDo(async () => {
					this.hgcComponentList = []
					this.loading = true
					const res = await apiGet({ id: this.updateId, isDealAcronym: 1 })
					if (res && res.data && res.data.project) {
						this.projectInfo = res.data.project
						if (res.data.project.jsonData) {
							this.componentList = JSON.parse(res.data.project.jsonData)
						}
						if (res.data.project.jsonDataAcronym) {
							const jsonDataAcronym = JSON.parse(
								res.data.project.jsonDataAcronym
							)
							this.componentList = [...this.componentList, ...jsonDataAcronym]
						}
						this.isOpenHgc = res.data.project.isOpenHgc
						if (res.data.project.jsonDataHgc) {
							this.hgcComponentList = JSON.parse(res.data.project.jsonDataHgc)
						}

						this.projectTypeName = res.data.project.projectConfigName
					}
					this.loading = false
				})
			} else {
				this.componentList = []
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
			this.fullscreen = false
		},
		handleFullScreen() {
			// 全屏查看
			this.fullscreen = !this.fullscreen
		}
	}
}
</script>

<style lang="less">
.view-pro-modal {
	.genetic-con {
		/*height: 600px;*/
		overflow: auto;
		.role-name-class {
			padding: 0 28px;
			font-size: 16px;
			font-weight: bold;
		}
	}
	.tab-files-wrap {
		.content-wrap {
			padding-top: 0;
		}
	}
	.title {
		font-size: 15px;
		font-weight: bold;
		padding: 16px 0;
		border-top: dashed 1px #515a6e;
	}

	.mrgin0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.view-pro-modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
	.genetic-wrapper {
		margin-left: 25px;
		h2 {
			font-weight: bolder;
			padding-bottom: 5px;
		}
	}
}
</style>
